import React, { useState } from "react";
import TableMain from "../Common/table/TableMain";
import EwalletTableFilter from "../Common/table/EwalletTableFilter";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const IwalletTable = (props) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("statement");
    const [selectStatement, setSelectedStatement] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const [sortOptions, setSortOptions] = useState({
        sortBy: "",
        direction: "",
    });
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );
    //------------------------------------------- API -------------------------------------------
    const statement = ApiHook.CallIwalletStatement(
        props.currentPage,
        itemsPerPage,
        selectStatement,
        sortOptions.sortBy,
        sortOptions.direction,
        activeTab
    );

    const setApiTab = (tab) => {
        setSelectedStatement(true);
    };
    const headers = [
        "amount",
        "transactionDate",
        "balance",
        "status",
        "releaseDate",
    ];

    return (
        <div className="ewallet_table_section">
            <div className="ewallet_table_section_cnt"></div>
            <div className="table-responsive min-hieght-table">
                {activeTab === "statement" && (
                    <TableMain
                        headers={headers}
                        data={statement?.data?.data}
                        startPage={1}
                        currentPage={statement?.data?.currentPage}
                        totalPages={statement?.data?.totalPages}
                        setCurrentPage={props.setCurrentPage}
                        type={"iwallet"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        activeTab={activeTab}
                        setApiTab={setApiTab}
                        setEpinSortOptions={setSortOptions}
                    />
                )}
            </div>
        </div>
    );
};

export default IwalletTable;
