import React, { useRef, useState } from "react";
import LabelledButton from "../buttons/LabelledButton";
import { useReactToPrint } from "react-to-print";
import { exportToCSV, exportToExcel } from "../../../utils/tableExports";
import { PrintReport } from "../PrintReport";
import { useNavigate } from "react-router";

const RepurchaseTableFilter = ({ headers, data, type }) => {
    const tableRef = useRef();
    const navigate = useNavigate();
    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    const handleBack = () => {
        navigate("/shopping");
    };

    return (
        <div className="filter_Section">
            <button
                className="print-button"
                style={{ backgroundColor: "white" }}
                onClick={handleBack}
            >
                <i
                    className="fa fa-angle-left"
                    style={{ fontSize: "1.3em" }}
                ></i>
            </button>
            <div className="row justify-content-end">
                <div className="col-md-4 text-end mob_filter_right">
                    <LabelledButton
                        className="fa fa-file-excel"
                        text=" Excel"
                        click={() => exportToExcel(data, headers, type)}
                    />
                    <LabelledButton
                        className="fa fa-file-text"
                        text=" CSV"
                        click={() => exportToCSV(data, headers, type)}
                    />
                    <LabelledButton
                        className="fa fa-print"
                        text=" Print"
                        click={handlePrint}
                    />
                </div>
                <div style={{ display: "none", padding: "10px" }}>
                    <PrintReport
                        ref={tableRef}
                        data={data}
                        headers={headers}
                        type={"repurchase-report"}
                    />
                </div>
            </div>
        </div>
    );
};

export default RepurchaseTableFilter;
