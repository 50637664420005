import React, { useState } from "react";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const QuickBalance = ({ tiles, currency, conversionFactor }) => {
    const { t } = useTranslation();
    const axtValue = useSelector((state) => state?.user?.axtvalue);
    const [dollarCurrency, setDollarCurrency] = useState("axt");
    const [dollarCurrencyIwallet, setDollarCurrencyIwallet] = useState("axt");

    const toDollarConverter = (axtCount) => {
        let convertedAmount = Number(axtCount) * axtValue;
        if (isNaN(convertedAmount)) return "0";
        if (convertedAmount === 0) return "0";

        let number = Math.abs(convertedAmount);
        if (number < 1e3) return number.toFixed(2);
        if (number < 1e6) return (number / 1e3).toFixed(2) + " k";
        if (number < 1e9) return (number / 1e6).toFixed(2) + " m";
        if (number >= 1e9) return (number / 1e9).toFixed(2) + " B";
    };
    function handleCurrencyChange(currencyType) {
        setDollarCurrency(currencyType);
    }
    function handleCurrencyChangeIwallet(currencyType) {
        setDollarCurrencyIwallet(currencyType);
    }
    return (
        <div data-tut="dashboardTiles" className="quick_balance_section">
            <div className="quick_balance_section_row">
                {dollarCurrency === "axt" ? (
                    <div className="quick_balance_Box" style={{ zIndex: "89" }}>
                        <button
                            className="currency-change-button"
                            onClick={() => {
                                handleCurrencyChange("$");
                            }}
                        >
                            <span> $ </span>
                        </button>
                        <div className="quick_balance_Box_ico">
                            <img src="/images/balance_ewallet_ico.svg" alt="" />
                        </div>
                        <div className="quick_balance_Box_cnt">
                            <span>{t("eWallet")}</span>
                            <strong>{`${
                                currency?.symbolLeft
                                    ? currency?.symbolLeft
                                    : "$"
                            } ${CurrencyConverter(
                                tiles?.ewallet ?? 0,
                                conversionFactor
                            )}`}</strong>
                        </div>
                    </div>
                ) : (
                    <div className="quick_balance_Box" style={{ zIndex: "89" }}>
                        <button
                            className="currency-change-button"
                            onClick={() => {
                                handleCurrencyChange("axt");
                            }}
                        >
                            <span style={{ fontSize: "0.9em" }}> AXT </span>
                        </button>
                        <div className="quick_balance_Box_ico">
                            <img src="/images/balance_ewallet_ico.svg" alt="" />
                        </div>
                        <div className="quick_balance_Box_cnt">
                            <span>{t("eWallet")}</span>
                            <strong>{`${"$"} ${toDollarConverter(
                                tiles?.ewallet ?? 0
                            )}`}</strong>
                        </div>
                    </div>
                )}

                {dollarCurrencyIwallet === "axt" ? (
                    <div className="quick_balance_Box" style={{ zIndex: "89" }}>
                        <button
                            className="currency-change-button"
                            onClick={() => {
                                handleCurrencyChangeIwallet("$");
                            }}
                        >
                            <span> $ </span>
                        </button>
                        <div className="quick_balance_Box_ico">
                            <img src="/images/balance_ewallet_ico.svg" alt="" />
                        </div>
                        <div className="quick_balance_Box_cnt">
                            <span>{t("iWallet")}</span>
                            <strong>{`${
                                currency?.symbolLeft
                                    ? currency?.symbolLeft
                                    : "$"
                            } ${CurrencyConverter(
                                tiles?.iwallet ?? 0,
                                conversionFactor
                            )}`}</strong>
                        </div>
                    </div>
                ) : (
                    <div className="quick_balance_Box" style={{ zIndex: "89" }}>
                        <button
                            className="currency-change-button"
                            onClick={() => {
                                handleCurrencyChangeIwallet("axt");
                            }}
                        >
                            <span style={{ fontSize: "0.9em" }}> AXT </span>
                        </button>
                        <div className="quick_balance_Box_ico">
                            <img src="/images/balance_ewallet_ico.svg" alt="" />
                        </div>
                        <div className="quick_balance_Box_cnt">
                            <span>{t("iWallet")}</span>
                            <strong>{`${"$"} ${toDollarConverter(
                                tiles?.iwallet ?? 0
                            )}`}</strong>
                        </div>
                    </div>
                )}

                <div className="quick_balance_Box">
                    <div className="quick_balance_Box_ico">
                        <img src="/images/balance_commision_ico.svg" alt="" />
                    </div>
                    <div className="quick_balance_Box_cnt">
                        <span>{t("commission")}</span>
                        <strong>{`${
                            currency?.symbolLeft ? currency?.symbolLeft : "$"
                        } ${CurrencyConverter(
                            tiles?.commission,
                            conversionFactor
                        )}`}</strong>
                        <div
                            className={`tp_comparison ${
                                tiles?.commissionSign ? "up" : "down"
                            }`}
                        >
                            <span>
                                {`${
                                    tiles?.commissionSign === "up" ? "+" : "-"
                                }${tiles?.commissionPercentage ?? "0"}%`}
                            </span>
                            <i
                                className={`fa-solid ${
                                    tiles?.commissionSign === "up"
                                        ? "fa-arrow-up"
                                        : "fa-arrow-down"
                                }`}
                            ></i>
                        </div>
                    </div>
                </div>

                <div className="quick_balance_Box">
                    <div className="quick_balance_Box_ico">
                        <img src="/images/balance_commision_ico.svg" alt="" />
                    </div>
                    <div className="quick_balance_Box_cnt">
                        <span>{t("totalCredit")}</span>
                        <strong>{`${
                            currency?.symbolLeft ? currency?.symbolLeft : "$"
                        } ${CurrencyConverter(
                            tiles?.totalCredit,
                            conversionFactor
                        )}`}</strong>
                        <div
                            className={`tp_comparison ${
                                tiles?.creditSign ? "up" : "down"
                            }`}
                        >
                            <span>
                                {`${tiles?.creditSign === "up" ? "+" : "-"}${
                                    tiles?.totalCreditPercentage ?? "0"
                                }%`}
                            </span>
                            <i
                                className={`fa-solid ${
                                    tiles?.creditSign === "up"
                                        ? "fa-arrow-up"
                                        : "fa-arrow-down"
                                }`}
                            ></i>
                        </div>
                    </div>
                </div>

                <div className="quick_balance_Box">
                    <div className="quick_balance_Box_ico">
                        <img src="/images/balance_pending_ico.svg" alt="" />
                    </div>
                    <div className="quick_balance_Box_cnt">
                        <span>{t("totalDebit")}</span>
                        <strong>{`${
                            currency?.symbolLeft ? currency?.symbolLeft : "$"
                        } ${CurrencyConverter(
                            tiles?.totalDebit,
                            conversionFactor
                        )}`}</strong>
                        <div
                            className={`tp_comparison ${
                                tiles?.debitSign ? "up" : "down"
                            }`}
                        >
                            <span>
                                {`${tiles?.debitSign === "up" ? "+" : "-"}${
                                    tiles?.totalDebitPercentage ?? "0"
                                }%`}
                            </span>
                            <i
                                className={`fa-solid ${
                                    tiles?.debitSign === "up"
                                        ? "fa-arrow-up"
                                        : "fa-arrow-down"
                                }`}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuickBalance;
