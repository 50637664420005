import React, { useEffect, useState } from "react";
// import Web3 from "web3";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ABI_USDT_T from "./abi/ABI_USDT_TEST.json";
import ABI_USDC_T from "./abi/ABI_USDC_TEST.json";

import ABI_USDT from "./abi/ABI_USDT.json";
import ABI_USDC from "./abi/ABI_USDC.json";
import ABI_AXT from "./abi/ABI_AXT.json";

import {
    useAccount,
    useBalance,
    useReadContract,
    useSendTransaction,
    useTransactionConfirmations,
    useWaitForTransactionReceipt,
    useWriteContract,
    useChainId,
    useSwitchChain,
} from "wagmi";
import { waitForTransactionReceipt, getChainId } from "@wagmi/core";

import { parseEther, parseUnits } from "viem";
import { getBalance } from "viem/actions";
import { HOSTING } from "../../config/config";

import { useWeb3ModalState } from "@web3modal/wagmi/react";
const supportedChain = HOSTING == "LIVE" ? 56 : 97; // bsc

// <WalletPay
// title="Pay with Web3 Wallet"
// amount={1}
// oninitiate={(initiate) => {
//   console.log({ initiate })
// }}
// onsubmit={(result) => {
//   console.log({ result })
// }}
// toAddress="0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23"
// // disabled={false}
// // loading={false}
// // onClick={() => null}
// token={"USDT"}
// />

const default_tokens = [
    {
        name: "USDT",
        token: "USDT",
        contract: "0x55d398326f99059fF775485246999027B3197955",
        abi: ABI_USDT,
        contract_test: "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48",
        abi_test: ABI_USDT_T,
    },
    // {
    //     name: "USDC",
    //     token: "USDC",
    //     contract: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    //     abi: ABI_USDC,
    //     contract_test: "0xDaDE7EAACe436fDfDA26C241628fC21E2240cF9F",
    //     abi_test: ABI_USDC_T,
    // },
    // {
    //     name: "AXT",
    //     token: "AXT",
    //     contract: "0xB6CF5b77B92a722bF34f6f5D6B1Fe4700908935E",
    //     abi: ABI_AXT,
    //     contract_test: "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48",
    //     abi_test: ABI_USDT_T,
    // },
    // { name: "ETH", token: "ETH", contract: null },
];

const USDTTransfer = ({
    onClick = () => null,
    oninitiate,
    onsubmit,
    amount,
    toAddress,
    title,
    style,
    metaMaskLoading = false,
    disabled = false,
    loading,
    tokens = default_tokens,
    token = "USDT",
    setLoading,
}) => {
    // amount = 0.001;
    // toAddress = "0x4B04b0bD233E5cf6EBf7E293124143eaf1077f23";

    // const usdt_bsc = "0x55d398326f99059fF775485246999027B3197955";
    // const usdt_test_bsc = "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48";
    // const usdt_test_eth = "0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF"; // Sepolia

    // const usdt_eth = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
    // const usdt_test_bsc = "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48";
    // const usdt_test_eth = "0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF"; // Sepolia

    const token_test = tokens.find((_) => _.token == token);

    const contractaddress =
        HOSTING == "LIVE"
            ? token_test?.contract
            : token_test?.contract_test ??
              "0xa3b01b8B0cA187540ECff3c97f0f6FFC6b2255cF";
    const contractabi =
        HOSTING == "LIVE" ? token_test?.abi : token_test?.abi_test;

    const { open, close } = useWeb3Modal();
    const { address, isConnecting, isDisconnected } = useAccount();

    const { chains, switchChain, error: switchChainError } = useSwitchChain();
    const { selectedNetworkId } = useWeb3ModalState();

    // E T H
    const {
        data: eth_hash,
        sendTransaction,
        error: eth_error,
        sendTransactionAsync,
    } = useSendTransaction();

    const { data: balance_eth } = useBalance({
        address: address,
    });

    const {
        data: successDataEth,
        isLoading: isConfirmingEth,
        isSuccess: isConfirmedEth,
        isError: isErrorEth,
    } = useWaitForTransactionReceipt({
        hash: eth_hash,
    });

    // T O K E N S

    const {
        data: hash,
        writeContract,
        isPending,
        onSccess,
        error,
    } = useWriteContract();

    const { data: balance } = useReadContract({
        abi: contractabi,
        address: contractaddress,
        functionName: "balanceOf",
        args: [address],
    });

    const {
        data: successData,
        isLoading: isConfirming,
        isSuccess: isConfirmed,
        isError,
    } = useWaitForTransactionReceipt({
        hash,
    });

    useEffect(() => {
        if (isConfirmed) {
            console.log("---SUCCESS----", {
                isConfirmed,
                successData,
                isConfirmedEth,
                successDataEth,
            });
            onsubmit({
                status: true,
                message: "Transaction Success.",
                data: successData,
            });
        }
    }, [isConfirmed, successData, isConfirmedEth, successDataEth]);

    useEffect(() => {
        if (error || eth_error) {
            let eth_error_ = JSON.parse(JSON.stringify(eth_error));
            onsubmit({
                status: false,
                message: eth_error_?.shortMessage || error?.message,
                data: eth_error_?.shortMessage || error?.message,
            });
        }
    }, [error, eth_error]);

    const { data: decimals } = useReadContract({
        abi: contractabi,
        address: contractaddress,
        functionName: "decimals",
    });

    const value = parseUnits(amount.toString(), parseInt(decimals ?? 0));

    // console.log({ decimals, value, address, contractaddress });

    const startTransaction = () => {
        if (parseFloat(balance_eth.formatted) < value) {
            onsubmit({
                status: false,
                message: `Not enough balance (${balance_eth.formatted})`,
            });
            return;
        }

        // sendTransaction({
        //     to: toAddress,
        //     value: parseEther(amount.toString()),
        // })
        // return

        sendTransactionAsync({
            to: toAddress,
            value: parseEther(amount.toString()),
        }).then(async (hash) => {
            oninitiate({
                status: true,
                message: "Transaction initiated.",
                hash,
            });
        });
    };

    const startTokenTransaction = () => {
        if (balance < value) {
            onsubmit({
                status: false,
                message: `Not enough balance (${balance})`,
            });
            return;
        }
        writeContract(
            {
                abi: contractabi,
                address: contractaddress,
                functionName: "transfer",
                args: [toAddress, value],
            },
            {
                onSettled: (hash) => {
                    // console.log("SETTLED", hash);
                    if (hash) {
                        oninitiate({
                            status: true,
                            message: "Transaction initiated.",
                            hash,
                        });
                    }
                },
                onError: (error) => {
                    // console.log("ERROR----", error);
                    onsubmit({
                        status: false,
                        message: error.shortMessage || error.message,
                    });
                },
                onSuccess: (result) => {
                    // console.log("SUCCESS----", result);
                    // onsubmit({
                    //     status: true,
                    //     message: "Transaction Success.",
                    //     data: result,
                    // });
                },
            }
        );
    };

    const onclick = () => {
        // check chain and switch
        // console.log(address , selectedNetworkId , supportedChain);
        if (address && selectedNetworkId != supportedChain) {
            switchChain({
                chainId: supportedChain,
            });
            return;
        }

        if (address) {
            setLoading(true);

            if (token_test?.contract) {
                startTokenTransaction();
            } else {
                startTransaction();
            }
            onClick?.();
        } else {
            open();
        }
    };

    // return (
    //     <Button
    //         onClick={onclick}
    //         title={address ? title ?? "Send USDT" : "Connect Wallet"}
    //         className="p-4 w-full bg-gradient-to-r from-primary to-secondary text-textColor pr-2"
    //         type="primary"
    //         disabled={disabled}
    //         loading={loading}
    //     />
    // );

    return (
        <div>
            <button
                // disabled={isPending || isConfirming || metaMaskLoading || disabled}
                className="btn btn-sm btn-primary mt-3"
                onClick={onclick}
                style={{
                    border: "2px solid black",
                    // paddingRight: 50,
                    // paddingLeft: 50,
                    padding: "10px 30px",
                    borderRadius: "30px",
                    backgroundColor: "#2c008a !important",
                    border: "solid 1px #2c008a",
                    ...style,
                }}
            >
                {address
                    ? loading
                        ? "proccessing"
                        : selectedNetworkId != supportedChain
                        ? `Switch chain to ${chains[0]?.name}`
                        : title ?? "Send AXT"
                    : "Connect Wallet"}
                {/* {loading
                    ? "proccessing"
                    : address
                    ? title ?? "Send AXT"
                    : "Connect Wallet"} */}
            </button>
        </div>
    );
};

export default USDTTransfer;
