import React, { useState } from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link, NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ShoppingLayout = ({ type = "repurchase" }) => {
    const params = useParams();
    const currencyList = {
        repurchase: "$",
        course: "CP",
    };
    const { t } = useTranslation();
    const [packageType, setPackageType] = useState(params?.type ?? type);
    const [packCurrency, setPackCurrency] = useState(
        currencyList[params?.type ?? type]
    );

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const items = ApiHook.CallRepurchaseItems(
        apiCallStatus,
        setApiCallStatus,
        packageType
    );

    const handlePackageTypeChange = (type) => {
        setPackageType(type);
        setApiCallStatus(true);
        setPackCurrency(currencyList[type]);
    };
    return (
        <>
            <div className="page_head_top">{t("shopping")}</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    <div className="col-md-4 text-end">
                        <div className="dropdown btn-group top_right_pop_btn_position">
                            <Link
                                to={"/repurchase-report"}
                                className="top_righ_pop_btn"
                            >
                                {t("repurchase_report")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ margin: "5px" }}>
                <button
                    className={
                        "btn_ewallt_page " +
                        (packageType === "repurchase" && "active")
                    }
                    onClick={() => {
                        handlePackageTypeChange("repurchase");
                    }}
                >
                    {t("creditPoints")}
                </button>
                <button
                    className={
                        "btn_ewallt_page " +
                        (packageType === "course" && "active")
                    }
                    onClick={() => {
                        handlePackageTypeChange("course");
                    }}
                >
                    {t("courses")}
                </button>
            </div>
            <ProductLists
                products={items?.data}
                packageType={packageType}
                packCurrency={packCurrency}
            />
        </>
    );
};

export default ShoppingLayout;
