import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import Skeleton from "react-loading-skeleton";
import CoinCodexWidget from "./CoinCodexWidget";

const CoinHistoryChart = () => {
    const { t } = useTranslation();

    // const chartData = iframeDoc.getElementById("coincodex-widget");

    return (
        <div className="col-md-7">
            <div className="coin-codex-box">
                <div className="tab-content coin-codex">
                    <CoinCodexWidget />
                </div>
            </div>
        </div>
    );
};

export default CoinHistoryChart;
