import React, { useState } from "react";
// import EwalletChart from "./EwalletDoughnut";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
const IwalletTiles = ({ tiles, currency, conversionFactor }) => {
    const { t } = useTranslation();
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );
    const axtValue = useSelector((state) => state?.user?.axtvalue);
    const [dollarCurrency, setDollarCurrency] = useState("axt");
    const toDollarConverter = (axtCount) => {
        let convertedAmount = Number(axtCount) * axtValue;
        if (isNaN(convertedAmount)) return "0";
        if (convertedAmount === 0) return "0";

        let number = Math.abs(convertedAmount);
        if (number < 1e3) return number.toFixed(2);
        if (number < 1e6) return (number / 1e3).toFixed(2) + " k";
        if (number < 1e9) return (number / 1e6).toFixed(2) + " m";
        if (number >= 1e9) return (number / 1e9).toFixed(2) + " B";
    };
    function handleCurrencyChange(currencyType) {
        setDollarCurrency(currencyType);
    }

    return (
        <div className="ewallet_top_cnt_sction">
            <div className="row">
                <div className="col-xl-3 col-md-12">
                    <div
                        className="ewallet_top_qc_balance_box"
                        style={{
                            position: "relative",
                        }}
                    >
                        <div className="row align-items-center">
                            {dollarCurrency === "axt" ? (
                                <div
                                    className="ewallet_top_qc_balance_box_cnt"
                                    style={{
                                        zIndex: "89",
                                    }}
                                >
                                    <button
                                        className="currency-change-button"
                                        onClick={() => {
                                            handleCurrencyChange("$");
                                        }}
                                    >
                                        <span> $ </span>
                                    </button>
                                    <div className="ewallet_top_qc_balance_box_cnt_ico">
                                        <img
                                            src={"/images/ewallet-ico2.png"}
                                            alt=""
                                        />
                                    </div>
                                    <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                        <div className="ewallet_top_qc_balance_box_cnt_head">
                                            {t("iwalletBalance")}
                                        </div>
                                        <div className="box_amnt_dv">
                                            <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                                {currency?.symbolLeft}{" "}
                                                {CurrencyConverter(
                                                    tiles?.iwalletBalance,
                                                    conversionFactor
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="ewallet_top_qc_balance_box_cnt"
                                    style={{ zIndex: "89" }}
                                >
                                    <button
                                        className="currency-change-button"
                                        onClick={() => {
                                            handleCurrencyChange("axt");
                                        }}
                                    >
                                        <span> AXT </span>
                                    </button>
                                    <div className="ewallet_top_qc_balance_box_cnt_ico">
                                        <img
                                            src={"/images/ewallet-ico2.png"}
                                            alt=""
                                        />
                                    </div>
                                    <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                        <div className="ewallet_top_qc_balance_box_cnt_head">
                                            {t("iwalletBalance")}
                                        </div>
                                        <div className="box_amnt_dv">
                                            <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                                ${" "}
                                                {toDollarConverter(
                                                    tiles?.iwalletBalance
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* {tiles?.daysRemaining && tiles?.daysRemaining != 0 && (
                    <div className="col-xl-3 col-md-12">
                        <div className="ewallet_top_qc_balance_box">
                            <div className="row align-items-center">
                                <div className="ewallet_top_qc_balance_box_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_ico">
                                        <img
                                            src={"/images/iwallet-ico1.png"}
                                            alt=""
                                        />
                                    </div>
                                    <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                        <div className="ewallet_top_qc_balance_box_cnt_head">
                                            {t("releaseIn")}
                                        </div>
                                        <div className="box_amnt_dv">
                                            <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                                {tiles.daysRemaining}
                                                <span
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        paddingLeft: "3px",
                                                        fontWeight: "lighter",
                                                    }}
                                                >
                                                    {t("dayss")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default IwalletTiles;
