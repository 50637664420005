import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const IwalletService = {
    callIwalletTiles: async () => {
        return callApi("iwallet-tiles");
    },
    callIwalletStatement: async (page, itemsPerPage, sortBy, direction) => {
        return callApi(`iwallet-statement?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`)
    },
    releaseIwallet: async () => {
        return API.post("iwallet-release")
            .then((response) => response)
            .catch((error) => Promise.reject(error));
    }
}