import React, { useState } from "react";
import OptionsButton from "../../components/Common/buttons/OptionsButton";
import EwalletTiles from "../../components/Ewallet/EwalletTiles";
import EwalletTable from "../../components/Ewallet/EwalletTable";
import FundTransfer from "../../components/Ewallet/FundTransfer";
import { NavLink } from "react-router-dom";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IwalletTiles from "../../components/Iwallet/IwalletTiles";
import IwalletTable from "../../components/Iwallet/IwalletTable";

const IwalletLayout = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();
    const style = {
        position: "absolute",
        inset: "0px auto auto 0px, margin: 0px",
        transform: "translate(190px, 42px)",
    };

    const handleIwalletRelease = () => {
        const release = ApiHook.CallIwalletRelease();
    };

    const iwallet = ApiHook.CallIwalletTiles();
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const moduleStatus = useSelector(
        (state) => state.dashboard?.appLayout?.moduleStatus
    );

    return (
        <>
            <div className="page_head_top">{t("iwallet")}</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    <div className="col-md-4">
                        {!!moduleStatus?.pin_status ||
                            (true && (
                                <>
                                    <NavLink
                                        to={"/e-wallet"}
                                        className="btn_ewallt_page"
                                        activeclassname="active"
                                    >
                                        {t("ewallet")}
                                    </NavLink>
                                    <NavLink className="btn_ewallt_page">
                                        {t("iwallet")}
                                    </NavLink>
                                    {/* <NavLink
                                        to={"/e-pin"}
                                        className="btn_ewallt_page"
                                    >
                                        {t("epin")}
                                    </NavLink> */}
                                </>
                            ))}
                    </div>
                    {false &&
                        iwallet?.data?.daysRemaining && ( // iwallet releasae in commission side
                            <div className="col-md-4 text-end">
                                <OptionsButton
                                    title={"release_iwallet"}
                                    handleOpen={handleIwalletRelease}
                                    style={style}
                                    type={"ewallet"}
                                />
                            </div>
                        )}
                </div>
            </div>
            <IwalletTiles
                tiles={iwallet?.data}
                currency={userSelectedCurrency}
                conversionFactor={conversionFactor}
            />
            <IwalletTable
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                currency={userSelectedCurrency}
            />
        </>
    );
};

export default IwalletLayout;
