import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";

const ProductForm = ({ product }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );

    const handleBack = () => {
        navigate(`/shopping/${product?.type}`);
    };
    const handleLinkClick = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
        <div className="productFormSec">
            {product?.type !== "course" && (
                <>
                    {" "}
                    <div className="mb-3">
                        <label htmlFor="quantity" className="form-label">
                            {t("quantity")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="quantity"
                            placeholder="1"
                            disabled
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="totalPV" className="form-label">
                            {t("total_pv")}
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="totalPV"
                            placeholder={product?.pairValue}
                            disabled
                        />
                    </div>
                </>
            )}
            {product?.type === "course" && (
                <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                        {t("title")}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder={product?.title ?? "Title"}
                        disabled
                    />
                </div>
            )}
            <div className="mb-3">
                <label htmlFor="price" className="form-label">
                    {t("price")}
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="price"
                    placeholder={`${
                        product?.type === "repurchase" ? "$" : "CP"
                    } ${CurrencyConverter(product?.price, conversionFactor)}`}
                    disabled
                />
            </div>

            <div className="mb-3">
                <label htmlFor="description" className="form-label">
                    {t("description")}
                </label>
                <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder={product?.description}
                    disabled
                />
            </div>
            {/* {product?.type === "course" && (
                <>
                    <div className="mb-3">
                        <label htmlFor="englishLink" className="form-label">
                            {t("englishLink")}
                        </label>
                        <div className="product-detail-link-head">
                            <input
                                type="text"
                                className="form-control"
                                id="englishLink"
                                placeholder={product?.videoLink ?? "Title"}
                                disabled
                            />
                            <button
                                className="product-detail-link"
                                onClick={() => {
                                    handleLinkClick(product?.videoLink);
                                }}
                            >
                                <i className="fa-solid fa-video"></i>
                            </button>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="italianLink" className="form-label">
                            {t("italianLink")}
                        </label>
                        <div className="product-detail-link-head">
                            <input
                                type="text"
                                className="form-control"
                                id="italianLink"
                                placeholder={
                                    product?.videoLinkItalian ?? "Title"
                                }
                                disabled
                            />
                            <button
                                className="product-detail-link"
                                onClick={() => {
                                    handleLinkClick(product?.videoLinkItalian);
                                }}
                            >
                                <i className="fa-solid fa-video"></i>
                            </button>
                        </div>
                    </div>
                </>
            )} */}

            <button
                type="button"
                className="btn text-white float-start back mt-4 rounded-3"
                style={{ backgroundColor: "#009a3e" }}
                onClick={handleBack}
            >
                {t("back")}
            </button>
        </div>
    );
};

export default ProductForm;
