import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import Loader from "react-js-loader";

const CourseModules = () => {
    const params = useParams();
    const packageId = params.id;
    const { t } = useTranslation();
    const items = ApiHook.CallCourseItems(packageId);
    console.log(items.data, "=/////////////////////////////////////");
    const handleLinkClick = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
        <div className="shoping_page_section">
            {items.data && (
                <div className="row">
                    {items?.data?.map((item, index) => (
                        <div className="col-xl-3 col-lg-4 col-md-4" key={index}>
                            <div
                                className="product_box"
                                style={{
                                    height: "250px",
                                    padding: "20px 10px 0px 10px",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "#ccebd9",
                                            width: "60px",
                                            height: "60px",
                                            borderRadius: "50px",
                                            justifyContent: "center",
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <i
                                            className="fa-solid fa-lines-leaning"
                                            style={{
                                                color: "#658d76",
                                                fontSize: "35px",
                                            }}
                                        ></i>
                                    </div>
                                    <span>{item?.title?.toUpperCase()}</span>
                                    <span>{item?.description}</span>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            width: "100%",
                                            position: "absolute",
                                            height: "50px",
                                            bottom: "0px",
                                        }}
                                    >
                                        <button
                                            className="product-detail-link2 product-detail-link2-left"
                                            onClick={() => {
                                                handleLinkClick(
                                                    item?.videoLink
                                                );
                                            }}
                                        >
                                            English{" "}
                                            <i className="fa-solid fa-video"></i>
                                        </button>
                                        <button
                                            className="product-detail-link2 product-detail-link2-right"
                                            onClick={() => {
                                                handleLinkClick(
                                                    item?.videoLinkItalian
                                                );
                                            }}
                                        >
                                            Italian{" "}
                                            <i className="fa-solid fa-video"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CourseModules;
