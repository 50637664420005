import React, { useEffect, useRef, useState } from "react";

const CoinCodexWidget = () => {
    const widgetRef = useRef(null);
    const scriptRef = useRef(null);

    useEffect(() => {
        const handleScriptLoad = () => {
            window.dispatchEvent(new Event("resize"));
        };
        let testLayoutElement = document.getElementById("test-layout");
        while (testLayoutElement.hasChildNodes()) {
            testLayoutElement.removeChild(testLayoutElement.firstChild);
        }
        // console.log(testLayoutElement);
        if (!scriptRef.current) {
            const script = document.createElement("script");
            script.src = `https://widget.coincodex.com/include.js?type=1&ticker=axioma-token&history_days=7&chartLineColor=009A3E&chartFillColor=dfeee1&textColor1=000&textColor2=617283&linkColor=117341&borderColor=ffffff&backgroundColor=ffffff&id=test-layout&height=1000px`;
            script.async = false;
            script.addEventListener("load", handleScriptLoad);
            document.body.appendChild(script);
            scriptRef.current = script;
        } else {
            scriptRef.current.addEventListener("load", handleScriptLoad);
        }

        return () => {
            scriptRef.current.removeEventListener("load", handleScriptLoad);
        };
    }, []);

    return (
        <>
            {" "}
            <div id="test-layout"></div>
        </>
    );
};

export default CoinCodexWidget;
