import { IwalletService } from "../../services/iwallet/iwallet";

export const IwalletTiles = async () => {
    try {
        const response = await IwalletService.callIwalletTiles();
        return response
    } catch (error) {
        return error.message
    }
}

export const IwalletStatement = async (page, itemsPerPage, sortBy, direction) => {
    try {
        const response = await IwalletService.callIwalletStatement(page, itemsPerPage, sortBy, direction);
        return response
    } catch (error) {
        return error.message
    }
}

export const IwalletRelease = async () => {
    try {
        const response = await IwalletService.releaseIwallet();
        return response
    } catch (error) {
        return error.message
    }
}